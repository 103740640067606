#paper {
  cursor: pointer;
  margin-top: 24px;
  position: relative;
}
.container-content {
  padding: 5px;
}

.defaultTheme {
  background-color: #ebedf6; /* or any default color */
}

.container-content .block-title {
  font-weight: 600;
  font-size: 30px;
  letter-spacing: 0.5px;
}
.container-content .block-text {
  position: relative;
  margin: 10px 0px 20px;
}
.container-image {
  background-repeat: no-repeat;
}
.container-item-block {
  margin-bottom: 1.5rem;
}
.grid-container {
  .module-title {
    padding: 1.5rem 2rem 1rem 1rem;
    font-weight: 600;
    font-size: 24px;
  }
  .module-text {
    font-size: 18px;
    padding: 0rem 2rem 0rem 1rem;
  }
}
.grid-container-main .module-img-container {
  padding: 0;
  display: flex;
  min-height: 300px;
  .course-img {
    border-radius: 10px 0px 0px 10px;
    object-fit: cover;
    height: 100%;
    width: 90%;
  }
}

.grid-container-main .module-svg-container {
  padding: 0;
  display: flex;
  min-height: 300px;
  .course-img {
    border-radius: 10px 0px 0px 10px;
    object-fit: cover;
    height: 100%;
    width: 90%;
  }
}

.highlightModule {
  border: 2px solid tomato;
  box-shadow: 3px 3px 12px #fa9393b3;
  border-radius: 1%;
}

@media only screen and (min-width: "0px") and (max-width: "900px") {
  .grid-container-main .module-img-container {
    padding-left: 16px;
  }
  .grid-container-main .module-svg-container {
    padding-left: 5px;
  }
  .grid-container-main .module-img-container .course-img {
    width: 100%;
  }
  .grid-container-main .module-svg-container .course-img {
    width: 100%;
  }
}

@media only screen and (min-width: "320px") and (max-width: "480px") {
  .module-title,
  .module-text {
    padding: 0rem 2rem;
  }
  .grid-container-main .module-img-container .course-img {
    border-radius: 5px;
    width: 100%;
  }

  .grid-container-main .module-svg-container .course-img {
    border-radius: 5px;
    width: 100%;
  }

  .grid-container {
    padding: 0rem;
  }
}
@media (min-width: "900px") {
  .grid-container-main {
    margin-left: 0;
  }
}
