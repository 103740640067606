@media print {
  .watermark {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
    font-size: 7rem;
    color: rgba(0, 0, 0, 0.1); /* Light color for watermark effect */
    z-index: 1000;
    pointer-events: none;
    user-select: none;
  }
  .course-desc-screen {
    display: none;
  }
  .download-courseview .loader-modal {
    display: none;
    visibility: hidden;
  }
}

@media screen {
  .fsDivider,
  .fsTitle,
  .watermark,
  .course-desc-print {
    display: none;
  }
}
.download-courseview {
  .download-course-image {
    width: 150px;
    height: 150px;
    margin-right: 16px;
  }
  .link-blocks {
    padding: 1rem;
    position: relative;
    border: 1px solid #e9e9ef;
    border-radius: 10px;
    background-color: var(--white);
    box-shadow: 0px 0px 20px #e9e9ef;
  }
  .link-blocks-content {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 10px;
  }
  .error-msg-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    .error-msg {
      align-items: center;
    }
  }
  .backdrop-modal {
    color: #fff;
    z-index: 1301;
    backdrop-filter: blur(8px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .white-box {
      background-color: white;
      padding: 20px;
      border-radius: 8px;
      box-shadow: 3;
      text-align: center;
      width: "80%";
      max-width: 400px;
      min-width: 300px;
      margin-bottom: 20rem;
      position: relative;
      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
      }
      .not-enabled {
        font-size: 16px;
        color: red;
        margin-bottom: 10px;
      }
    }
  }
}

@media (max-width: 375px) {
  .backdrop-modal .white-box {
    width: 300px;
  }
}
