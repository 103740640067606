.match-view {
  width: 100%;
  position: relative;
  display: inline-block;
  vertical-align: top;
  .container-block {
    padding: 20px;
    border-radius: 10px;
    overflow: hidden;
  }
  .block-title {
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 10px;
  }
  .block-text {
    font-size: 18px;
    position: relative;
    margin: 10px 0px 20px;
    word-wrap: break-word;
  }
  .match-options {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    font-size: 1rem;
  }
  .block-options {
    margin-top: 20px;
    position: relative;
    width: 100%; /* Ensure block options take full width of the block */
    max-width: 100%; /* Prevent overflow beyond parent width */
    overflow: hidden;
  }

  .block-options .block-option {
    display: block;
    max-width: 100%; /* Prevent overflow beyond block width */
    overflow: hidden; /* Hide content overflow */
    text-overflow: ellipsis; /* Truncate overflowing text */
    white-space: nowrap; /* Prevent text wrapping */
  }

  // .block-options.block-options-left {
  //   border-right: 40px solid transparent;
  //   position: relative;
  //   width: 50%;
  //   display: table-cell;
  //   vertical-align: top;
  // }
  // .block-options.block-options-right {
  //   border-left: 40px solid transparent;
  //   position: relative;
  //   width: 50%;
  //   display: table-cell;
  //   vertical-align: top;
  // }
  .bg-selected {
    background: black;
    color: #fff;
    border-radius: 10px;
  }
  .bg-default {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }
  @media (max-width: 770px) {
    .block-options.block-options-right {
      width: 100%;
      border-left: 0;
      display: block;
    }
    .match-options {
      flex-direction: column;
    }
  }
  .match-options .block-options > div {
    width: auto;
  }
  .match-options .block-option {
    display: block;
  }
  .block-options > div {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 200px;
    cursor: pointer;
    margin: 0px 20px 20px 0px;
    border-radius: 10px;
  }
  .block-option .block-image {
    display: block;
    width: 200px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .block-image {
    position: relative;
    display: table-cell;
    vertical-align: top;
    width: 400px;
    border-radius: 10px;
    background-color: #1a1a1a;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .block-option .block-details {
    display: block;
    padding: 20px;
    max-width: 100%; /* Prevent details from exceeding block width */
    overflow: hidden;
    text-overflow: ellipsis; /* Truncate overflowing text */
    white-space: nowrap;
  }
  .block-details {
    position: relative;
    display: table-cell;
    vertical-align: top;
  }
  .block-option .block-text {
    margin: 0px;
  }
  .block-text {
    position: relative;
    margin: 10px 0px 20px;
    max-width: 100%; /* Prevent text from exceeding block width */
    text-overflow: ellipsis; /* Truncate text */
    white-space: nowrap; /* Prevent wrapping */
  }
  .block-table {
    position: relative;
    display: table;
    width: 100%;
  }
}
.match-block .match-colors {
  position: absolute;
  left: 8px;
  top: -23px;
  display: block;
  z-index: 99;
  .one-color {
    display: inline-block;
    vertical-align: top;
    height: 7px;
    width: 20px;
    margin-right: 3px;
    cursor: inherit;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
}

.required {
  color: red;
}

.highlightBlock {
  border: 2px solid tomato;
  box-shadow: 3px 3px 12px #fa9393b3;
}
