.scorm-view {
  padding: 1rem;
  position: relative;
  .block-title {
    // padding: 1rem 0rem 0rem 1rem;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 10px;
  }
  .block-scorm {
    position: relative;
    vertical-align: top;
    margin-bottom: 20px;
    // display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    .padlet-scorm {
      border-radius: 10px !important;
    }
    p {
      width: 100%;
    }
    iframe {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }
    iframe[title="YouTube video player"] {
      width: 70%;
    }
    .typeform-scorm {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
// @media (min-width: 600px) and (max-width: 1000px) {
//   .scorm-view .block-scorm {
//     width: 100%;
//     iframe[title="YouTube video player"] {
//       width: 100%;
//     }
//   }
// }
// @media (min-width: 0px) and (max-width: 500px) {
//   .scorm-view .block-scorm {
//     width: 100%;
//     & iframe[title="YouTube video player"] {
//       height: 300px;
//       width: 100%;
//     }
//   }
// }
