.audio-tag {
  width: 100%;
}
.label-tag {
  color: var(--grey);
}

.highlightBlock {
  border: 2px solid tomato;
  box-shadow: 3px 3px 12px #fa9393b3;
}
.video-recording-contents {
  margin-bottom: 1rem;
}
