.course-overview {
  margin-left: 15px;
  margin-top: 25px;
  .grid-continer {
    background-color: #365082;
    padding: 10px;
    border-radius: 8px;
    color: #fff;
    align-items: center;
    .text-content-grid {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 1;
      padding-right: 2;
      padding-left: 0;
    }
    .course-image-grid {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 0;
      padding-left: 0;
    }
  }
}
