.headerMain {
  position: fixed;
  top: 0;
  left: 0;
  background-image: linear-gradient(to right, #141415 0%, #0060b4 40%, #0060b4 70%);
  z-index: 999;
  .headerToolbar {
    // padding: 0px 62px;
    display: flex;
    justify-content: space-between;
    .logoMain {
      display: flex;
      align-items: center;
      .fmText {
        font-weight: bold;
        font-size: 1.5rem;
        letter-spacing: 1px;
        color: #fff;
      }
    }
  }
  .reviewerHeaderText {
    background: var(--postlogin-primary-color);
    padding: 2px;
    display: flex;
    justify-content: flex-start;
    .reviewViewTextMain {
      font-size: 14px;
      color: var(--white);
      text-align: center;
      width: 95%;
    }
    .reviewExitWrapper {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      cursor: pointer;
      .exitText {
        float: right;
        font-size: 14px;
        // text-align: center;
        color: white;
        white-space: nowrap;
      }
    }
  }
}
.headerImg {
  text-decoration: none;
  color: inherit;
}
.navBar {
  display: flex;
  align-items: center;
  flex-grow: 0;
  .loggedInIconMain {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    .headerIcon {
      width: 2rem;
      height: 2rem;
    }
    .helpText {
      color: #fff;
      cursor: pointer;
    }
    .advancedOption {
      color: #fff;
      display: flex;
      align-items: center;
      gap: 0.2rem;
      cursor: pointer;
    }
    .headerIcon:hover {
      cursor: pointer;
    }
  }
  .profileHeader {
    background-color: var(--grey);
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 40px;
    min-height: 40px;
    border-radius: 50%;
    .profileImage {
      border-radius: 50%;
      object-fit: cover;
      vertical-align: middle;
      height: 100%;
      width: 100%;
    }
  }
  .profileHeader:hover {
    cursor: pointer;
  }
}

.helpAboutButton {
  display: flex;
  // gap: 2rem;
}

.profileList {
  padding: 10px;
  font-weight: 600;
  cursor: pointer;
}
.profileList:hover {
  color: var(--btnBlue);
}
.logo {
  height: 4rem;
  width: 4rem;
  padding: 0.5rem;
}
.customDropdown :nth-child(3) {
  height: fit-content;
  min-height: 0;
  ul {
    padding-top: 0;
    padding-bottom: 0;
  }
}

@media (max-width: 900px) {
  .headerMain {
    margin: 0px auto;
    margin-bottom: 1rem;
    .headerToolbar {
      padding-right: 0;
    }
  }
  .logo {
    background-size: contain;
    height: 60px;
    width: 60px;
  }

  .help-btn,
  .about-btn {
    display: none;
  }
}
@media (max-width: 720px) {
  .navBar {
    gap: 1.5rem;
    .loggedInIconMain {
      gap: 1rem;
      .headerIcon {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
    .profileHeader {
      min-width: 35px;
      min-height: 35px;
    }
  }
  .headerMain {
    padding: 0px 11px;
  }
}
@media (max-width: 369px) {
  .navBar {
    gap: 0.5rem;
  }
}
@media (max-width: 480px) {
  .headerMain .fmText {
    display: none;
  }
  .advanceOptionSection {
    display: none;
  }
}

@media print {
  .headerMain {
    display: none;
  }
}
