.link-block {
  position: relative;
  iframe {
    width: 100%;
  }
  .block-title {
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 10px;
  }
  .block-text {
    position: relative;
    // margin: 10px 0px 20px;
    overflow-wrap: break-word;
  }
  .block-provider-button {
    background-color: #409fff;
  }
}
.link-block p #instagram-embed-0 {
  border-radius: 10px !important;
}

@media screen and (max-width: 600px) {
  .link-block iframe {
    width: 100%;
    height: 270px;
  }
}
@media screen and (min-width: 601px) and (max-width: 900px) {
  .link-block iframe {
    width: 100%;
  }
}

@media screen and (min-width: 901px) {
  .link-block iframe {
    width: 70%;
  }
}
