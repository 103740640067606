@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Serif:wght@500;600;700&display=swap");
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.125rem;
  // background: #1A1A1A;
  // padding: 2rem;
  // color: #fff;
}
* {
  font-family: Figtree, sans-serif;
  line-height: 30px;
  letter-spacing: 0.5px;
}
// @media (max-width: 769px) {
//   body {
//     padding: 1rem;
//   }
// }

@media print {
  body {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
}
