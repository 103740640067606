.fill-block {
  padding: 1rem;
  position: relative;
  .with-art > *:not(.block-actions) {
    padding-right: 220px;
  }
  .block-title {
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 10px;
    .required {
      font-weight: bold;
      font-size: 20px;
      color: red;
      padding: 0.5rem;
    }
  }
  .block-text {
    position: relative;
    margin: 10px 0px 20px;
  }
  .entity-title {
    width: 100%;
    font-size: 17px;
    padding: 10px;
    color: #1a1a1a;
    margin: 10px 0px;
    border: 2px solid transparent;
    outline: none;
    border-radius: 4px;
  }
  .fill-contents {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .blank-fill {
    position: relative;
    display: inline-block;
    display: block;
    width: 200px;
    background-color: rgba(0, 0, 0, 0.2);
    padding: 5px;
  }
  .highlightBlock {
    border: 2px solid tomato;
    box-shadow: 3px 3px 12px #fa9393b3;
  }
}

@media screen and (max-width: 600px) {
  .fill-block {
    .fill-contents {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      width: 100%;
    }
  }
}

@media screen and (min-width: 601px) and (max-width: 900px) {
  .fill-block {
    .fill-contents {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      width: 70%;
    }
  }
}
